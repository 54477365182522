<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card :loading="loading" :disabled="loading" loader-height="2">
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <v-btn
                rounded
                color="primary"
                v-if="$nova.hasRight(right, 'add')"
                @click="
                  Inputdialog = true;
                  inputObj = {};
                  errors = {};
                  inputObj.status = true;
                  type = 'string';
                  inputObj.hidden = true;
                "
                elevation="0"
                small
                ><v-icon class="mr-1">mdi-plus</v-icon>Add</v-btn
              >
              <v-btn icon small class="ml-1" @click="refreshData()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <lb-string
                class="ml-1"
                label="Search"
                outlined
                hidedetails
                v-model="seachList"
              ></lb-string>
              <v-spacer></v-spacer>
              <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              >
            </div>
          </v-card-text>
          <v-card-text>
            <!-- <v-simple-table class="pbc-view-table" dense>
              <thead>
                <tr class="">
                  <th scope="#" class="px-4 text-center">#</th>

                  <th scope="Code">Code</th>
                  <th scope="Description">Description</th>
                  <th scope="Data Type">Data Type</th>
                  <th scope="Defult Value">Possible Value</th>
                  <th scope="Defult Value">Defult Value</th>
                  <th scope="Defult Value">Verifications</th>
                  <th scope="Status">Admin Only</th>
                  <th scope="Status">Alias</th>

                  <th scope="Action">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(v, k) in searchResult">
                  <tr :key="k">
                    <td
                      :class="`${
                        v.status
                          ? 'border-left-transparent'
                          : 'border-left-error'
                      }`"
                    >
                      <span>{{ k + 1 }}</span>
                    </td>
                    <td>
                      <span
                        v-html="$nova.getHighlitedText(v.code || '', seachList)"
                      ></span>
                    </td>
                    <td>
                      <span>{{ v.name }}</span>
                    </td>
                    <td>
                      <span>{{ v.type }}</span>
                    </td>
                    <td>
                      <span>{{ v.possiblevalue }}</span>
                    </td>
                    <td>
                      <span>{{ v.value }}</span>
                    </td>
                    <td>
                      <span>
                        <div v-for="(k, h) in v.verification" :key="h">
                          <v-chip label x-small>{{ k.code }}</v-chip>
                        </div>
                      </span>
                    </td>
                    <td>
                      <v-chip x-small label v-if="v.hidden" color="info"
                        >Yes</v-chip
                      >
                      <v-chip x-small label v-else color="warning">No</v-chip>
                    </td>
                    <td>{{ v.alias }}</td>
                    <td>
                      <div class="d-flex justify-center">
                        <v-tooltip
                          bottom
                          content-class="tooltip-bottom"
                          v-if="$nova.hasRight(right, 'edit')"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="editInput(v)"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit InputParams</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          content-class="tooltip-bottom"
                          v-if="
                            v.status && $nova.hasRight(right, 'enable/disable')
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="success"
                              v-bind="attrs"
                              v-on="on"
                              @click="disabledInput(v._id)"
                            >
                              <v-icon>mdi-check-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Enable</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          content-class="tooltip-bottom"
                          v-else-if="
                            !v.status && $nova.hasRight(right, 'enable/disable')
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="error"
                              v-bind="attrs"
                              v-on="on"
                              @click="enableInput(v._id)"
                            >
                              <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Disable</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </v-simple-table> -->

            <DataTable
              :headers="headers"
              :items="formatedData"
              :enableslot="['action', 'hidden']"
              :loading="loading"
            >
              <template v-slot:hidden="item">
                <v-chip x-small label v-if="item.item.hidden" color="info"
                  >Yes</v-chip
                >
                <v-chip x-small label v-else color="warning">No</v-chip>
              </template>
              <template v-slot:action="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip
                    bottom
                    content-class="tooltip-bottom"
                    v-if="$nova.hasRight(right, 'edit')"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="editInput(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit InputParams</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    content-class="tooltip-bottom"
                    v-if="
                      item.status && $nova.hasRight(right, 'enable/disable')
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        @click="disabledInput(item._id)"
                      >
                        <v-icon>mdi-check-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Enable</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    content-class="tooltip-bottom"
                    v-else-if="
                      !item.status && $nova.hasRight(right, 'enable/disable')
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="enableInput(item._id)"
                      >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Disable</span>
                  </v-tooltip>
                </div>
              </template>
            </DataTable>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="Inputdialog" max-width="600" persistent>
      <v-card>
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text" v-if="inputObj._id"
            >Update Input Parameters</span
          >
          <span class="subtitle-1 white--text" v-else
            >Create Input Parameters</span
          >

          <v-spacer></v-spacer>
          <v-btn icon @click="Inputdialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="mb-0 py-1">
              <lb-string
                label="Code"
                v-model="inputObj.code"
                :error="errors['code']"
              />
            </v-col>
            <v-col cols="12" class="mb-0 py-1">
              <lb-string
                label="Description"
                v-model="inputObj.name"
                :error="errors['name']"
              />
            </v-col>
            <v-col cols="12" class="mb-0 py-1">
              <lb-string label="Description Alias" v-model="inputObj.alias" />
            </v-col>
            <v-col cols="12" class="mb-0 py-1">
              <lb-dropdown
                label="Data Type"
                v-model="type"
                :items="typeArr"
                itemtext="name"
                itemvalue="value"
              />
            </v-col>
            <v-col
              cols="12"
              class="mb-0 py-1 mb-2"
              v-if="dropdowntypes.indexOf(type) > -1"
            >
              <lb-list
                type="numberarray"
                v-if="type === 'numberdropdown'"
                label="Possible Value"
                v-model="possiblevaluelist"
              />
              <lb-list
                type="stringarray"
                v-else-if="type === 'stringdropdown'"
                label="Possible Value"
                v-model="possiblevaluelist"
              />
              <lb-list
                type="daterangearray"
                v-else-if="type === 'datedropdown'"
                label="Possible Value"
                v-model="possiblevaluelist"
              />
              <lb-list
                type="montharray"
                v-else-if="type === 'monthdropdown'"
                label="Possible Value"
                v-model="possiblevaluelist"
              />
              <lb-list
                type="yeararray"
                v-else-if="type === 'yeardropdown'"
                label="Possible Value"
                v-model="possiblevaluelist"
              />
              <lb-list
                type="timearray"
                v-else-if="type === 'timedropdown'"
                label="Possible Value"
                v-model="possiblevaluelist"
              />
              <lb-list
                type="daterangearray"
                v-else-if="type === 'daterangedropdown'"
                label="Possible Value"
                v-model="possiblevaluelist"
              />
              <lb-list
                type="timerangearray"
                v-else-if="type === 'timerangedropdown'"
                label="Possible Value"
                v-model="possiblevaluelist"
              />
            </v-col>
            <v-col cols="12" class="mb-0 py-1">
              <lb-number
                v-if="type === 'number'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-date
                v-else-if="type === 'date'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-month
                v-else-if="type === 'month'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-year
                v-else-if="type === 'year'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-time
                v-else-if="type === 'time'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-daterange
                v-else-if="type === 'daterange'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-timerange
                v-else-if="type === 'timerange'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-list
                type="stringarray"
                v-else-if="type === 'dropdown'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-list
                type="daterangearray"
                v-else-if="type === 'daterangearray'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-list
                type="timerangearray"
                v-else-if="type === 'timerangearray'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-list
                type="numberarray"
                v-else-if="type === 'numberarray'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-list
                type="stringarray"
                v-else-if="type === 'stringarray'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-list
                type="datearray"
                v-else-if="type === 'datearray'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-list
                type="montharray"
                v-else-if="type === 'montharray'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-list
                type="yeararray"
                v-else-if="type === 'yeararray'"
                label="Defult Value"
                v-model="inputObj.value"
              />
              <lb-list
                type="timearray"
                v-else-if="type === 'timearray'"
                label="Defult Value"
                v-model="inputObj.value"
              />

              <lb-dropdown
                v-else-if="dropdowntypes.indexOf(type) > -1"
                label="Defult Value"
                :items="possiblevaluelist"
                v-model="inputObj.value"
              />

              <lb-string v-else label="Defult Value" v-model="inputObj.value" />
            </v-col>

            <v-col cols="6" class="my-0 py-1">
              <v-switch
                v-model="inputObj.status"
                inset
                class="mt-0"
                color="success"
                label="Status"
              ></v-switch>
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <v-switch
                v-model="inputObj.hidden"
                inset
                class="mt-0"
                color="success"
                label="Admin Only"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            v-if="inputObj._id"
            @click="updateInput(inputObj._id)"
            >Update</v-btn
          >
          <v-btn small color="primary" v-else @click="creatInput()"
            >Create</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataTable from "../../../../views/common/DataTable.vue";
export default {
  data() {
    return {
      right: "conserve_input_parameter",
      loading: false,
      seachList: null,
      Inputdialog: false,
      inputObj: {},
      type: "",
      typeEdit: null,

      typeArr: [
        { name: "Number", value: "number" },

        { name: "String", value: "string" },

        { name: "Date", value: "date" },

        { name: "Month", value: "month" },

        { name: "Year", value: "year" },

        { name: "Time", value: "time" },

        { name: "Date Range", value: "daterange" },

        { name: "Time Range", value: "timerange" },

        { name: "Number Array", value: "numberarray" },

        { name: "String Array", value: "stringarray" },

        { name: "Date Array", value: "datearray" },

        { name: "Month Array", value: "montharray" },

        { name: "Year Array", value: "yeararray" },

        { name: "Time Array", value: "timearray" },

        { name: "Date Range Array", value: "daterangearray" },

        { name: "Time Range Array", value: "timerangearray" },

        { name: "Number Dropdown", value: "numberdropdown" },

        { name: "String Dropdown", value: "stringdropdown" },

        { name: "Date Dropdown", value: "datedropdown" },

        { name: "Month dropdown", value: "monthdropdown" },

        { name: "Year Dropdown", value: "yeardropdown" },

        { name: "Time Dropdown", value: "timedropdown" },

        { name: "Date Range Dropdown", value: "daterangedropdown" },

        { name: "Time Range Dropdown", value: "timerangedropdown" },
      ],
      dropdowntypes: [
        "numberdropdown",
        "stringdropdown",
        "datedropdown",
        "monthdropdown",
        "yeardropdown",
        "timedropdown",
        "daterangedropdown",
        "timerangedropdown",
      ],
      statusArr: [
        { _id: 1, name: "Open" },
        { _id: 0, name: "Close" },
      ],
      flogArr: [
        { _id: 1, name: "Admin Only" },
        { _id: 0, name: "Hidden" },
      ],
      inputParamList: null,
      editInputID: null,
      errors: {},
      possiblevaluelist: [],
      formatedData: [],
      headers: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
          lock: true,
          visible: false,
        },
        {
          value: "code",
          text: "Code",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "description",
          text: "Description",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "datatype",
          text: "Data Type",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        // {
        //   value: "value",
        //   text: "Defult Value",
        //   sortable: true,
        //   filterable: true,
        //   lock: true,
        //   visible: false,
        // },
        // {
        //   value: "verification",
        //   text: "Verification",
        //   filterable: true,
        //   lock: true,
        //   visible: false,
        //   sortable: true,
        //   datatype: "object",
        //   showvalue: "code",
        // },
        {
          value: "hidden",
          text: "Admin Only",
          sortable: true,
          filterable: true,
          filterList: [
            { name: "Yes", value: true },
            { name: "No", value: false },
          ],
          lock: true,
          visible: false,
        },
        {
          value: "alias",
          text: "Alias",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "created_at",
          text: "Created At",
          sortable: true,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: true,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
        },
      ],
    };
  },
  components: {
    DataTable,
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  computed: {
    searchResult() {
      let tempList = this.inputParamList;
      if (this.seachList != "" && this.seachList) {
        tempList = tempList.filter((item) => {
          return item.name.toUpperCase().includes(this.seachList.toUpperCase());
        });
      }
      return tempList;
    },
  },
  methods: {
    formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      this.inputParamList.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexno: index + 1,
          code: item.code,
          description: item.name,
          datatype: item.type,
          created_at: item.created_at,
          updated_at: item.updated_at,
          verification: item.verification,
          status: item.status,
          value: item.value,
          alias: item.alias,
          hidden: item.hidden,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
    },
    refreshData() {
      this.loading = true;
      this.axios
        .post("v2/conserve/inputparameter/getinputparameterwithverification")
        .then((dt) => {
          if (dt.data.status === "success") {
            this.inputParamList = dt.data.data;
            this.formateData();
          } else throw new Error(dt.data.message);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    creatInput() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/inputparameter/add", { data: this.inputObj })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.inputObj = {};
            this.Inputdialog = false;
            this.$store.commit("sbSuccess", "add successfully");
            this.refreshData();
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            } else
              throw new Error(
                dt.data.message || "Error adding input parameter"
              );
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editInput(item) {
      let verItem = { ...item };
      verItem.__oldpossiblevalue = verItem.possiblevalue;
      verItem.__oldtype = verItem.type;
      this.inputObj = verItem;
      this.type = this.inputObj.type;
      this.Inputdialog = true;
      this.possiblevaluelist = verItem.possiblevalue;
      this.errors = {};
    },
    updateInput(id) {
      this.loading = true;
      this.axios
        .post("/v2/conserve/inputparameter/edit/" + id, {
          data: this.inputObj,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.inputObj = {};
            this.Inputdialog = false;
            this.$store.commit("sbSuccess", "Updated successfully");
            this.refreshData();
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            } else
              throw new Error(
                dt.data.message || "Error update input parameter"
              );
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    disabledInput(id) {
      this.loading = true;
      this.axios
        .post("/v2/conserve/inputparameter/disable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "disable successfully");
            this.refreshData();
          } else
            throw new Error(dt.data.message || "Error disable input parameter");
        })
        .catch((err) => {
          console.log(err);
          this.inputObj = {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enableInput(id) {
      this.loading = true;
      this.axios
        .post("/v2/conserve/inputparameter/enable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "enable successfully");
            this.refreshData();
          } else
            throw new Error(dt.data.message || "Error enable input parameter");
        })
        .catch((err) => {
          console.log(err);
          this.inputObj = {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    type: function (d) {
      let poss_value = d;
      this.inputObj.value = undefined;
      this.inputObj.type = d;
      if (poss_value.indexOf("dropdown") > -1) {
        if (this.inputObj.__oldtype === d)
          this.inputObj.possiblevalue = this.inputObj.__oldpossiblevalue;
        else this.inputObj.possiblevalue = [];
      }
    },
    possiblevaluelist: function (d) {
      this.inputObj.possiblevalue = d;
    },
  },
};
</script>